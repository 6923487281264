.logo {
  height: 40px !important;
  width: 40px !important;
  // min-width: 123.547px;
}

.brandName {
  max-height: 64px;

  & > :global(.ant-typography):first-child {
    font-size: 20px;
    font-weight: 900;
  }
  :global(.ant-typography) {
    display: block;
    font-size: 18px;
    // font-weight: 600;
  }
}

:global .ant-row-rtl {
  :local .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
}

@media screen and (max-width: 600px) {
  .brandName {
    display: none;
  }
}

