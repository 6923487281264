@import '../../variables';

@image-width: 240px;

.collapsible {
  & > :global(.ant-collapse-item) {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  :global(.ant-collapse-header) {
    padding: 0 !important;
  }
}

.header_container {
  width: 100%;
}

:global(.btn-custom-link) {
  font-family: Proxima Nova Rg Regular, sans-serif;
  font-weight: 400;
  color: @primary-color;
  cursor: pointer;
}

.inlineContentPanelLi {
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.checkboxGroup {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  max-width: calc(calc(@image-width * 157 / 275) + 157px);
  margin: auto !important;
  // transform: translateX(-50%);

  :global(.ant-radio-wrapper-checked) {
    :local(.radioText) {
      font-weight: 600;
    }
  }

  &__container {
    width: 100%;
    position: relative;
    text-align: center;

    :global(.ant-image) {
      max-width: @image-width;
    }

    image {
      width: 100%;
      height: 100%;
    }
  }

  label {
    position: absolute;
    margin: 0;
    width: 100px;
    gap: 20px;

    &:first-child {
      top: 15px;
      flex-flow: row-reverse;
      left: -15px;
    }

    &:nth-child(2) {
      top: 15px;
      right: -15px;
    }

    &:nth-child(3) {
      bottom: 0px;
      flex-flow: row-reverse;
      left: -15px;
    }

    &:nth-child(4) {
      bottom: 0px;
      right: -15px;
    }
  }
}

.advancedText {
  color: @primary-color;
}

.advancedSectionContainer {
  margin-top: 10px;

  // & :global(.ant-collapse-content-box) {
  //   padding: 0;
  // }
  :global(.ant-collapse-header) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  :global(.ant-collapse-content-box) {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
  }
}

@media (max-width: 400px) {
  .checkboxGroup {
    label {
      &:first-child {
        top: 15px;
        flex-flow: row-reverse;
        left: -20px;
      }

      &:nth-child(2) {
        top: 15px;
        right: -20px;
      }

      &:nth-child(3) {
        bottom: 0px;
        flex-flow: row-reverse;
        left: -20px;
      }

      &:nth-child(4) {
        bottom: 0px;
        right: -20px;
      }
    }
  }
}

.sliderPostfixInput :global(.ant-input-number-input-wrap) {
  color: black;
  background: white;

  input {
    cursor: default !important;
  }
}

