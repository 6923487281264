@import url('../../../variables.less');

.modal {
  :global .ant-modal-footer {
    background-color: @footer-bg-color;
    text-align: center;
  }
}
.container {
  .list {
    padding-inline: 24px !important;
    margin: 0 !important;
  }
  .card {
    background-color: @footer-bg-color;
    border: 1px solid @border-color;

    & > :global(.ant-typography) {
      font-size: 14px;
      display: block;
      padding-inline: 16px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  :global {
    .ant-collapse {
      background-color: inherit;
      border: none;
    }
    .ant-collapse-item {
      border-bottom: none;
    }
    .ant-collapse-content {
      background-color: @footer-bg-color;
      border-top: none;
    }
    .ant-collapse-content-box {
      & > .ant-space > .ant-space-item:last-child {
        border-top: 1px dashed @border-color;
        padding-top: 16px;
      }
    }
  }
}

.validationModal {
  :global(.ant-result-title) {
    text-align: center;
    font-size: 15px;
  }
  :global .ant-modal-footer {
    background-color: @footer-bg-color;
    text-align: center;
  }
  :global {
    .ant-result-subtitle {
      text-align: center;
      & > .ant-typography {
        text-align: left;
      }
    }
  }
}

