@import '~antd/lib/style/themes/variable.less';
@import url('../../../variables.less');

.layout {
  min-height: 100vh;
}

main {
  min-width: 100% !important;
}

.mainContent {
  width: 100%;
  max-width: @contentMaxWidth;
  margin-inline: auto;
  min-height: 280px;
  padding: 30px;
}

.spinnerContainer {
  z-index: 2000;
}

@media screen and (max-width: @screen-lg) {
  .mainContentContainerContent {
    padding-inline: 12px !important;
  }

  .mainContent {
    padding-inline: 0;
  }
}

