.modalContent {
  letter-spacing: 0.06rem;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .modalContent {
    letter-spacing: 0.06rem;
    text-align: center;
  }
}

