@import url('../../variables.less');

.questionIcon {
  color: @primary-color;
  vertical-align: middle;
}

.description {
  color: @gray-color !important;
}

