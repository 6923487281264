@import url('../../../variables.less');

.root {
  padding: 0 75px;
  :global(.ant-btn-dangerous) {
    color: #ea7041;
    border-color: #ea7041;
  }

  // .text {
  //   display: block;
  //   padding: 0 30px;
  // }

  .messageBox {
    text-align: center;

    border: 1px solid @border-color !important;
  }

  .button {
    min-width: 75%;
    white-space: normal;
  }
}

.header {
  text-align: center;
  :global(.ant-typography) {
    color: #52c41a !important;
    font-weight: 600;
  }
  .checkedCircle {
    color: #52c41a !important;
    font-size: 32px;
  }
}

@media screen and (max-width: 600px) {
  .root {
    padding: 0 30px;
  }
}

