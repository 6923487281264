.radioBtn {
  padding: 0 !important;
  height: auto !important;
  border-radius: 10px !important;

  .radio {
    height: auto;
    width: 100%;
    white-space: initial;
    text-align: left;
    padding: 12px 16px;
    margin-right: 0 !important;
  }
}

