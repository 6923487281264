.radioGroup {
  width: 100% !important;

  :global(.ant-space-item) {
    flex: 1;
    max-width: 150px;
  }
}

// Switcher preview
.preview {
  width: 100%;
  max-width: 400px;
  margin: auto;

  .crossIcon {
    cursor: pointer;
  }

  .btn {
    // border: 0;
    padding: 2.4px 15px;
    // font-weight: 600;
    // border-radius: 4px;
    // height: auto;
    &:hover {
      border-color: inherit !important;
    }
    &:focus {
      border-color: inherit !important;
    }
    &:active {
      border-color: inherit !important;
    }
  }

  :global(.ant-card-head) {
    display: none;
    // min-height: 32px;
    // border-bottom: none !important;
  }
  :global(.ant-card-body) {
    padding-top: 10px !important;
  }
  :global(.ant-card-extra) {
    padding: 12px 0 !important;
  }
  :global(.ant-select) {
    width: 100%;
  }
  :global(.ant-space) {
    width: 100%;
  }
  :global(#country-selector-wrapper .select2-container) {
    width: 100% !important;
  }
}

.popoverOverlay :global(.ant-popover-inner-content) {
  padding: 0;
}

