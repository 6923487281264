.select2-container {
  letter-spacing: 0.06rem;
  width: 100% !important;
  height: auto;
}
#country-selector-wrapper .select2-container {
  width: max-content !important;
}
.select2-container--default .select2-selection--single {
  border-radius: 4px !important;
  height: auto !important;
}
.select2-selection {
  border: 1px solid #d7d7d7 !important;
  padding: 4px 7px 5.5px 7px;
  height: auto;
}
.select2-container .select2-dropdown {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 0px;
  border-radius: 4px;
  z-index: 999 !important;
}

.select2-search__field:focus {
  outline: none !important;
}

.select2-search__field,
.select2-search__field::placeholder {
  color: inherit;
}

.mlveda-select-root {
  display: inline-block;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 28px;
  top: 5px;
  right: 1px;
}

.flag-img {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  margin-right: 8px;
  display: inline-block !important;
}
.dropdown-menu {
  min-width: 300px;
  min-height: 200px;
  padding: 10px 20px 20px 20px;
  line-height: 1.3;
  font-size: inherit;
}
.open > .dropdown-menu {
  margin-top: 20px;
}

.mlveda-heading {
  font-weight: bold;
  margin-bottom: 8px;
}
.mlveda-stack-item {
  margin-bottom: 16px;
  margin-top: 10px;
}
.mlveda-cross-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}
.mlveda-continue-btn button {
  border: 0;
  padding: 12px 30px;
  font-weight: 600;
  width: 100%;
  border-radius: 4px;
}
.mlveda-cross-icon button {
  background-color: transparent;
  padding: 0px;
  border: none;
}
.mlveda-cross-icon button svg {
  fill: #aaa;
  height: 20px;
  width: 20px;
}
.mlveda-continue-btn {
  margin-bottom: 0px;
}

.mlveda-select-root .select2-selection {
  border: 1px solid #d7d7d7 !important;
  padding: 4px 7px 5.5px 7px;
  height: auto;
}

.select2-search--dropdown {
  padding: 12px 20px 12px 20px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-radius: 50px;
  border-color: #ddd;
  padding-left: 15px;
  box-shadow: none;
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 20px;
}

.select2-results__option {
  padding: 8px 20px 8px 20px;
  line-height: 1.4;
}

.select2-container--default .select2-results__option--selected {
  color: inherit !important;
  font-weight: bold !important;
  background-color: #fff !important;
  text-decoration: underline;
  text-underline-position: under;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #fff !important;
  color: inherit;
  /* font-weight: bold; */
}

.select2-results__message {
  text-align: center;
}

.select2-results__options {
  margin-bottom: 12px;
}

/* .select2-container {
  width: auto !important;
} */

.mlveda-result-span {
  word-break: break-all;
  /* white-space: nowrap; */
  text-align: left;
  /* text-overflow: ellipsis; */
  /* max-width: 150px; */
  overflow: hidden;
}

.result-selected > .mlveda-result-span {
  /* word-break: break-all; */
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  max-width: 150px;
  overflow: hidden;
}

.select2-results__option:hover {
  font-weight: bold;
}

.select2-results__option:hover .mlveda-result-span {
  text-decoration: underline;
  text-underline-position: under;
}

/* .select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable
  .mlveda-result-span {
  text-decoration: underline;
} */

.select2-container--default .select2-selection--single {
  border-radius: 4px !important;
}

.mlveda-result-main {
  display: flex;
  width: 100%;
  align-items: center;
}
.mlveda-dropdown-select {
  border-radius: 4px;
  border: 1px solid #d7d7d7 !important;
  padding: 4px 10px;
  z-index: 999 !important;
  background: #fff;
  position: relative;
}

.mlveda-label {
  margin-bottom: 8px;
}

.mlveda-dropdown-select .select2-container .select2-dropdown {
  margin-top: 0px;
  min-width: auto;
}
.mlveda-caret {
  margin-left: 5px !important;
  display: none;
}
.dropdown.open .mlveda-caret {
  transform: rotate(180deg);
}

.mlveda-mr-8 {
  margin-right: 8px;
}
.mlveda-dropdown-main {
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  height: 25px;
  overflow: hidden;
  color: inherit;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: inherit;
}
#select2-mlveda-language-selector-container,
#select2-mlveda-country-selector-container {
  height: 25px;
  line-height: 25px;
}

.modal-backdrop.in {
  display: block;
}

.mlveda-language-message {
  display: none;
}

.modal-dialog {
  margin: 0px auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 530px;
}
.mlveda-modal-close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
#ic_close {
  fill: #aaa;
}
.fade .modal-dialog {
  transform: translate(0, -100%);
}

.in .modal-dialog {
  transform: translate(0, 0);
}
.flag-img-circle {
  background-image: url(https://d38dvuoodjuw9x.cloudfront.net/circle.png);
  background-repeat: no-repeat;
  width: 64px !important;
  height: 64px !important;
  background-size: unset !important;
  display: block;
  margin: 0 auto;
  transform: scale(0.4);
  transform-origin: 0 0;
}

/* rectangle */
.flag-img-rectangle {
  background-image: url(https://d38dvuoodjuw9x.cloudfront.net/rectangle.png);
  background-repeat: no-repeat;
  transform: scale(0.56);
  transform-origin: 0 0;
  width: 48px !important;
  height: 36px !important;
  background-position: -627px -257px !important;
  background-size: unset !important;
}

.modal-content {
  padding: 30px;
  text-align: center;
  line-height: 1.3;
  text-align: center;
  position: relative;
  inset: 0px;
  display: block;
}
.mlveda-modal-heading {
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
  display: block;
}
.mlveda-modal-subheading {
  margin-top: 20px;
}
.shop-now-button {
  border: 0;
  padding: 12px 30px;
  font-weight: bold;
  width: 100%;
  border-radius: 4px;
  margin-top: 20px;
}

.select2-container .select2-dropdown {
  margin-top: 0px;
  min-width: 200px;
  /* max-width: 200px !important; */
}

.select2-container--default .select2-selection--single {
  padding: 4px 7px 4px 7px;
  height: auto;
  min-height: 35px !important;
}
#mlveda-not-shipping-country-div {
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
  }
}

.image-circle {
  max-height: 25.6px;
  max-width: 25.6px;
}

.image-rectangle {
  max-height: 20px;
  max-width: 25px;
}

/* flags */
.circle {
  background-image: url(https://dtu2uu2x8487j.cloudfront.net/round-flags.png);
  background-repeat: no-repeat;
  transform: scale(0.4);
  transform-origin: 0 0;
  width: 64px !important;
  height: 64px !important;
  background-position: -10px -10px;
  background-size: unset !important;
}

.circle-AU {
  background-position: -94px -262px;
}
.circle-CA {
  background-position: -514px -178px;
}
.circle-DE {
  background-position: -94px -598px;
}
.circle-FR {
  background-position: -346px -682px;
}
.circle-GB {
  background-position: -514px -682px;
}
.circle-IN {
  background-position: -850px -598px;
}
.circle-JP {
  background-position: -514px -850px;
}
.circle-KR {
  background-position: -934px -262px;
}
.circle-US {
  background-position: -1270px -1018px;
}

#mlveda-not-shipping-modal .mlveda-modal-flag.circle {
  background-image: url(https://dtu2uu2x8487j.cloudfront.net/round-flags.png);
  background-repeat: no-repeat;
  transform: none;
  width: 64px !important;
  height: 64px !important;
  background-size: unset !important;
  display: block;
  margin: 0 auto;
}

.rectangle {
  background-image: url(https://dtu2uu2x8487j.cloudfront.net/rectangle-flags.png);
  background-repeat: no-repeat;
  transform: scale(0.56);
  transform-origin: 0 0;
  width: 48px !important;
  height: 36px !important;
  background-position: -282px -962px;
  background-size: unset !important;
}

.rectangle-AU {
  background-position: -78px -178px;
}
.rectangle-CA {
  background-position: -146px -346px;
}
.rectangle-DE {
  background-position: -486px -66px;
}
.rectangle-FR {
  background-position: -554px -234px;
}
.rectangle-GB {
  background-position: -554px -346px;
}
.rectangle-IN {
  background-position: -486px -570px;
}
.rectangle-JP {
  background-position: -418px -626px;
}

.rectangle-KR {
  background-position: -690px -234px;
}

.rectangle-US {
  background-position: -894px -906px;
}

#mlveda-not-shipping-modal .mlveda-modal-flag.rectangle {
  background-image: url(https://dtu2uu2x8487j.cloudfront.net/rectangle-flags.png);
  background-repeat: no-repeat;
  transform: none;
  width: 48px !important;
  height: 36px !important;
  background-size: unset !important;
  display: block;
  margin: 0 auto;
}
