@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry
@import './variables.less';

@import '~react-quill/dist/quill.snow.css';
@import '~quill-emoji/dist/quill-emoji.css';

@layout-body-background: #fff;
@form-item-margin-bottom: 0px;

body {
  max-width: 100vw !important;
  overflow-x: hidden !important;
}

.no-searchbar {
  display: none !important;
}

.las {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px;
}

@media screen and (min-width: @screen-sm) {
  .designs {
    & > .ant-col:nth-child(2) > .ant-space {
      padding-top: 22px !important;
    }
  }
}

.stepContainer {
  .leftSection {
    padding: @section-padding;
    border: 1px solid @border-color;
    // max-width: 66.67% !important;
    // flex: 0 0 66.67% !important;
    // border-right: none;
    // border-bottom: 1px solid @border-color;
  }

  .rightSection {
    text-align: left;
    padding-inline: @section-padding !important;
    // max-width: 33.33% !important;
    // flex: 0 0 33.33% !important;
  }
  .save-btn-wrapper {
    background-color: @footer-bg-color;
    padding: 15px 0;
    border: 1px solid @border-color;
    border-top: none;
    text-align: center;
  }
}

// .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
//   border-top: 1px solid @border-color;
// }

.autoGeolocation {
  padding: 0 100px;
}

// ant card
.ant-card-bordered {
  border: 1px solid @border-color;
}

// ant modal
.ant-modal-title {
  font-weight: 400 !important;
}

// warning message
.ant-form-item-explain-warning {
  font-weight: 600;
}

// // settings page title
// .ant-page-header-heading-title {
//   font-size: 18px !important;
// }

.ant-btn,
a.ant-btn {
  height: auto;
  padding: 6px 15px !important;
}

@media screen and (max-width: @screen-md) {
  .autoGeolocation {
    padding: 0 50px;
  }

  .stepContainer {
    .leftSection {
      border: 1px solid @border-color;
    }

    .rightSection {
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      border-left: 1px solid @border-color;
      border-right: 1px solid @border-color;
    }
    .save-btn-wrapper {
      border-top: 1px solid @border-color;
    }
  }
}

@media screen and (max-width: @screen-lg) {
  .mobileHidden {
    display: none !important;
  }
}

@screenlg-min: @screen-lg + 1;

@media screen and (min-width: @screenlg-min) {
  .pcHidden {
    display: none !important;
  }
}

@media screen and (max-width: @screen-sm) {
  .autoGeolocation {
    padding: 0;
  }
}

.fullWidth {
  width: 100%;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.pl-24 {
  padding-left: 24px;
}
.pl-40 {
  padding-left: 40px;
}
.previewText {
  font-weight: 600;
  text-decoration: underline;
  color: black;
}

.page-header {
  .ant-page-header-heading-left {
    align-items: flex-start;
  }

  .ant-page-header-back {
    margin-top: 10px;
  }
  .ant-page-header-heading-title {
    font-size: 18px;
  }

  .ant-page-header-heading-title > div {
    gap: 0 !important;

    .ant-space-item {
      &:first-child {
        line-height: 22px;
      }

      &:last-child {
        line-height: 16px;
      }
    }
  }
}

.ant-page-header-heading-sub-title {
  color: @gray-color;
  font-weight: 400;
  white-space: normal;
}

.container {
  box-sizing: content-box;
  max-width: @contentMaxWidth;
  padding-inline: @layoutPadding;
  margin-inline: auto;
}

.section {
  max-width: @contentMaxWidth;
  margin-inline: auto;
  margin-top: 10px;
  width: 100%;
  //box-sizing: content-box;
  // padding-inline: @layoutPadding;
}

.ant-page-header {
  padding-inline-start: 0;
  padding-block-end: 0;
}

.ant-page-header-back {
  margin-top: 7px !important;
}
.ant-page-header-back-button {
  border: 1px solid @primary-color !important;
  border-radius: 50%;
  padding: 7px !important;
  svg {
    color: @primary-color;
  }
}

.ant-divider {
  border-color: @border-color;
}

