@import url('../../variables.less');

.root {
  width: 100%;
  height: 100%;
  justify-content: flex-start;

  > :global(.ant-space-item:last-child) {
    height: 100%;
  }

  .recommended {
    text-align: center;

    :global(.ant-tag) {
      width: max-content;
      // width: 100%;
      padding: 0 20px;
      background-color: #55a3fd;
      font-size: 11px;
      font-weight: 600;
      color: #fff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: -5px;
    }
  }

  .badge {
    display: block;
    height: 100%;
    :global(.anticon) {
      z-index: 100;
    }
    .checkedIcon {
      color: @primary-color;
      font-size: 18px;
    }
    :global(.ant-space) {
      width: 100%;
      height: 100%;
    }
  }
}

:global(.ant-radio-button-wrapper) {
  height: 100% !important;
  width: 100%;
  padding: 0;
  border-radius: 10px !important;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid @primary-color !important;
  }
}

.radioButton {
  // border-radius: 20px !important;
  text-align: center;
  height: auto;

  .content {
    padding: 10px 5px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;

    :global(.ant-space-item) {
      height: 24px;
    }

    :global(.ant-typography) {
      display: block;
      padding: 3px 0px;
    }
  }

  .description {
    padding: 7px 5px;
    display: block;
    line-height: 1.4;
    // white-space: nowrap !important;
  }
}

.selected {
  border-color: @primary-color !important;
}
.btn {
  border-color: @border-color !important;
}

.description {
  border-top: 1px solid @border-color;
}

