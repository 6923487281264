@import '~antd/lib/style/themes/variable.less';
@import '../../../variables';

.menuWrapper {
  display: inline-block;
  vertical-align: middle;
  flex-grow: 1;
  position: absolute;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.logoContainer {
  min-width: max-content;
  line-height: 20px !important;
}

// .logoCol :global(.ant-image) {
//   vertical-align: middle;
//   max-height: 64px;
// }

// progress bar
.progressBar {
  width: 100%;
  text-align: center;
  padding-inline: 20px;

  :global {
    .ant-progress-success-bg,
    .ant-progress-bg {
      background-color: @primary-color;
    }
    .ant-progress-status-success .ant-progress-bg {
      background-color: #52c41a;
    }
    .ant-progress {
      max-width: 50%;
    }
  }
}

.header {
  // box-sizing: content-box !important;
  max-width: @headerMaxWidth;
  margin: 0 auto;
  // width: calc(100vw - @layoutPadding - @layoutPadding);
  overflow: hidden;
  background: #fff !important;
  display: flex;
  flex-flow: row nowrap;
  direction: ltr;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;

  &::after {
    content: '';
    position: absolute;
    top: @layout-header-height;
    border-bottom: 1px solid @border-color-base;
    left: 0;
    width: 100%;
    // box-shadow: @box-shadow-base;
  }

  :global .ant-menu-root {
    width: 350px;
    border-bottom: none;
  }
}

@media screen and (max-width: @screen-xs) {
  // .header {
  //   padding-inline: 12px !important;
  // }
  .progressBar {
    width: 100%;
    text-align: center;
    padding-inline: 10px;
    :global(.ant-progress) {
      max-width: 100%;
    }
  }

  .logoContainer {
    margin-left: 0 !important;
  }

  .rightSectionSpace {
    margin-right: -8px;
  }
}

.drawer {
  :global .ant-drawer-content-wrapper {
    width: 100%;
    max-width: 300px;
  }
}

.avatar {
  background-color: @success-color;
}

.menuReviewLinkBtn {
  padding: 0;
}

.appStatusText {
  white-space: nowrap;
  vertical-align: middle;
}

.emptyStatus {
  flex-grow: 1;
}

.barButton {
  border-radius: 4px;
  padding: 5px 0 !important;
}

.verticalMenu {
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;

  & > li:last-child {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.exclaimationIcon svg {
  width: 22px;
  height: 22px;
  color: orange;
}
.confirmText {
  font-size: 17px;
}

